// main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // Vuex store
import VueMasonry from 'vue-masonry-css';

import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

// Import global CSS
import './assets/global.css';

// Toast configuration options
const toastOptions = {
  position: 'top-right',
  timeout: 5000,
  closeOnClick: true,
  pauseOnHover: true,
  showProgressBar: true,
  maxToasts: 5,
  newestOnTop: true,
  transition: 'slide',
  draggable: true,
  draggablePercent: 0.6,
  focusable: false,
  parseHtml: false,
};

// Parse the query string to get the lastRoute parameter
const urlParams = new URLSearchParams(window.location.search);
const lastRoute = urlParams.get('lastRoute');

// Create the Vue app instance
const app = createApp(App);

// Install plugins and global configurations
app.use(router);
app.use(store);
app.use(VueMasonry);
app.use(Toast, toastOptions);

// If there's a lastRoute parameter, navigate to that route
if (lastRoute) {
  router.push(lastRoute);
}

// Mount the app
app.mount('#app');
